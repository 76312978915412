import { defineStore } from 'pinia';

export const useGeneralStore = defineStore('general-store', {
    state: () => {
        return {
            mainMenuOpen: false,
            mainNavigationData: {},
            footerData: {},
            toggledMenu: null
        };
    },

    getters: {
        isMainMenuOpen() {
            return this.mainMenuOpen;
        },
        getToggledMenu() {
            return this.toggledMenu;
        }
    },

    actions: {
        // Menu overlay
        toggleMainMenu(value) {
            this.mainMenuOpen = !this.mainMenuOpen;
            if (this.mainMenuOpen) {
                this.toggledMenu = value;
            } else {
                this.toggledMenu = null;
            }
        },

        openMainMenu() {
            this.mainMenuOpen = true;
        },

        closeMainMenu() {
            this.mainMenuOpen = false;
        },

        setNavigation(navigation) {
            if (!navigation) {
                return;
            }

            if (navigation?.mainNavigation) {
                this.mainNavigationData = navigation?.mainNavigation;
            }

            if (navigation?.footer) {
                this.footerData = navigation?.footer;
            }
        },
    }
});
