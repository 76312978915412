<template>
    <div class="hamburger-menu">
        <button
            :class="{ 'open': mainMenuOpen }"
            type="button"
            class="hamburger-menu__button button--transparent button--icon-only"
            aria-label="Open menu"
            @click="generalStore.toggleMainMenu(); changeThemeVariant();"
        >
            <i class="dn-icon hamburger-menu__icon">
                <span />
                <span />
                <span />
                <span />
            </i>
        </button>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useGeneralStore } from '~/store/general';

const props = defineProps({
    themeVariant: {
        type: String,
        required: false,
        default: 'theme-dark',
    }
});

const generalStore = useGeneralStore();
const { mainMenuOpen } = storeToRefs(generalStore);

const variant = ref(props.themeVariant);

const changeThemeVariant = () => {
    if(variant.value === 'theme-dark') {
        variant.value = 'theme-light';
    } else {
        variant.value = 'theme-dark';
    }
};

const head = computed(() => {
    return {
        bodyAttrs: {
            'data-theme-variant': variant.value
        }
    };
});

watch(() => variant.value, () => {
    useHead(head);
});
</script>

<style lang="less" src="./TheHamburgerMenu.less"></style>
