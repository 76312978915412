<template>
    <DelayHydration>
        <nav class="navigation-bar">
            <div class="navigation-bar__container">
                <div class="navigation-bar__desktop">
                    <nav
                        v-for="item in menuItems"
                        :key="`nav-bar-${item.title}`"
                        @click="generalStore.toggleMainMenu(item.title); changeThemeVariant();"
                    >
                        <!-- TODO: replace with $t(item.title) -->
                        {{ item.title }}
                        <ul
                            class="navigation-bar__submenu"
                            :class="{ 'navigation-bar__submenu--active': generalStore.isMainMenuOpen && generalStore.getToggledMenu === item.title }"
                        >
                            <li
                                v-for="(link, i) in item.links"
                                :key="`sub-menu-${item.title}-${i}`"
                                class="navigation-bar__submenu-list"
                            >
                                <CraftLink
                                    :link="link"
                                    @click="generalStore.closeMainMenu()"
                                />
                            </li>
                        </ul>
                    </nav>
                </div>

                <div class="navigation-bar__actions">
                    <TheLanguageSwitcher />

                    <BaseButton
                        class="dn-button--icon"
                        element="a"
                        to="/zoek"
                    >
                        <span class="navigation-bar__desktop">
                            {{ $t('search') }}
                        </span>
                        <BaseIcon icon="search" />
                    </BaseButton>

                    <TheHamburgerMenu
                        class="navigation-bar__mobile"
                        :theme-variant="props.themeVariant"
                    />
                </div>

                <BaseButton
                    class="navigation-bar__cta base-button__contra"
                >
                    {{ $t('buyTickets') }}
                </BaseButton>
            </div>
        </nav>
    </DelayHydration>
</template>

<script setup>
import { useGeneralStore } from '~/store/general';

const generalStore = useGeneralStore();
const {locale: activeLocale} = useI18n();

const props = defineProps({
    themeVariant: {
        type: String,
        required: false,
        default: 'theme-dark',
    },
    menuItems: {
        type: Array,
        required: true
    },
});

const variant = ref(props.themeVariant);

const changeThemeVariant = () => {
    if(variant.value === 'theme-dark') {
        variant.value = 'theme-light';
    } else if(variant.value === 'theme-light') {
        variant.value = 'theme-dark';
    }
};

const head = computed(() => {
    return {
        bodyAttrs: {
            'data-theme-variant': variant.value
        }
    };
});

watch(() => variant.value, () => {
    useHead(head);
});
</script>

<style lang="less" src="./TheNavBar.less"></style>
