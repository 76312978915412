<template>
    <DelayHydration>
        <transition name="menu-overlay">
            <FocusLoop
                :is-visible="generalStore.isMainMenuOpen"
                class="menu-overlay"
                role="dialog"
                :auto-focus="false"
            >
                <nav class="menu-overlay__container">
                    <DnAccordion
                        :items="menuItems"
                        class="menu-overlay__accordion"
                    >
                        <template #title="{ item }">
                            <h2>
                                {{ item.title }}
                            </h2>
                        </template>

                        <template #default="{ item }">
                            <ul class="menu-overlay__accordion-list">
                                <li
                                    v-for="link in item.links"
                                    :key="link.text"
                                >
                                    <CraftLink
                                        :link="link"
                                        @click="generalStore.closeMainMenu()"
                                    />
                                </li>
                            </ul>
                        </template>

                        <template #icon>
                            <BaseIcon icon="chevron-down"/>
                        </template>
                    </DnAccordion>
                </nav>
            </FocusLoop>
        </transition>
    </DelayHydration>
</template>

<script setup>
import {FocusLoop} from '@vue-a11y/focus-loop';
import {useGeneralStore} from '~/store/general';
import DnAccordion from '@digitalnatives/accordion';

defineProps({
    menuItems: {
        type: Array,
        required: true
    },
});
// Close the menu when changing routes
const generalStore = useGeneralStore();
const route = useRoute();

watch(() => route.path, () => {
    generalStore.closeMainMenu();
});
</script>

<style lang="less" src="./TheMenuOverlay.less"></style>
